<template>
  <div class="liten-then-choose-words-game-container layout-border">
    <!-- <audio :src="pinyinAudioSrc" ref="audioBox"></audio> -->
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentIndex"
        @changeTheNumbers="changeTheNumbers"
      />
      <!-- page  select img-->
      <div class="choose-img-area" v-if="isImgPage">
        <div class="img-area">
          <div class="img-content">
            <img
              v-for="(item, index) in allImgList[currentIndex]"
              :src="item.bgImg"
              alt=""
              :key="index + 'img'"
              class="img-item"
              :class="{
                active: index === chooseImgIndex,
                isCorrect:
                  item.id === answerList[currentIndex] &&
                  index === chooseImgIndex,
              }"
              @click="handleChooseImg(item.id, index)"
            />
          </div>
        </div>
        <div class="trumpet-area-right">
          <span class="trumpet-area" @click.stop="playPinyinAudio">
            <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
          </span>
        </div>
      </div>
      <!-- page  chooose hanzi -->
      <div class="choose-hanzi-area" v-else>
        <div class="answer-area">
          <div class="answer-img">
            <img :src="chooseHanziList[currentIndex].bgImg" alt="" />
          </div>
          <div class="answer-hanzi-area" v-if="!isShowAllWords">
            <div
              class="hanzi-item"
              v-for="(item, index) in chooseHanziList[currentIndex]
                .hanziRightList"
              :key="index + 'right'"
              :class="{
                active: insertIndex === index,
                hasInserted: rightHanziIndexArr.indexOf(index + 1) > -1,
              }"
            >
              <span class="pinyin font-pinyin-medium">{{ item.pinyin }}</span>
              <span class="font-hanzi-medium">{{ item.hanzi }}</span>
            </div>
          </div>
          <div class="answer-right-hanzi-area" v-else>
            <div class="right-hanzi-box">
              <span class="pinyin font-pinyin-medium">{{
                chooseHanziList[currentIndex].wordsPinyin
              }}</span>
              <span class="font-hanzi-medium">{{
                chooseHanziList[currentIndex].wordsHanzi
              }}</span>
            </div>
          </div>
        </div>

        <div class="choose-hanzi-area">
          <div
            class="hanzi-item"
            v-for="(item, index) in chooseHanziList[currentIndex]
              .hanziChooseList"
            :key="index + 'choose'"
            :class="{ dispear: choosedHanziArr.indexOf(index) > -1 }"
            @click="handleChooseHanzi(item.id, index)"
          >
            <span class="pinyin font-pinyin-medium">{{ item.pinyin }}</span>
            <span class="font-hanzi-medium">{{ item.hanzi }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools.js";
export default {
  props: {
    allImgList: {
      type: Array,
      require: true,
    },
    trumpetImg: {
      type: String,
      require: true,
    },
    chooseHanziList: {
      type: Array,
      require: true,
    },
    answerList: {
      type: Array,
      require: true,
    },
  },
  components: {
    SceneSwitchThumbnails,
    PageButton,
    AudioPlayer,
  },

  data() {
    return {
      isStartPlaying: null,
      songUrl: null,
      isShowAllWords: false,
      rightHanziIndexArr: [],
      lockIndex: 0,
      choosedHanziArr: [],
      insertIndex: 0,
      chooseImgIndex: null,
      isImgPage: true,
      imgSocketInfo: {},
      hanziSocketInfo: {},
      audioSocketInfo: {},
      clickMenuSocketInfo: {},

      currentIndex: 0,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      buttonList: [
        {
          image: require("@/assets/img/01-Menu/menu-listen-1.svg"),
          startNumberRange: 0,
          endNumberRange: 0,
        },
        {
          image: require("@/assets/img/01-Menu/menu-listen-2.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/01-Menu/menu-listen-3.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/01-Menu/menu-listen-1.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
    };
  },
  created() {
    // startConfetti();
  },
  mounted() {
    this.$bus.$on("clickChoosedImg", ({ id, index }) => {
      this.handleChooseImg(id, index, true);
    });
    this.$bus.$on("clickChoosedHanzi", ({ id, index }) => {
      this.handleChooseHanzi(id, index, true);
    });
    this.$bus.$on("chooseImgGameClickAudio", () => {
      this.playPinyinAudio("socket", true);
    });
    this.$bus.$on("chooseImgGameClickMenu", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickChoosedImg");
    this.$bus.$off("clickChoosedHanzi");
    this.$bus.$off("chooseImgGameClickAudio");
    this.$bus.$off("chooseImgGameClickMenu");
  },
  watch: {
    hanziSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003002,
          data: { value },
          text: "ListenThenChooseWordsGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    imgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003001,
          data: { value },
          text: "ListenThenChooseWordsGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003003,
          data: { value },
          text: "ListenThenChooseWordsGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003004,
          data: { value },
          text: "ListenThenChooseWordsGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      this.currentIndex = index;
      this.isImgPage = true;
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.initGame();
    },
    playPinyinAudio(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random(),
        };
      }
      this.isStartPlaying = Math.random();
      this.songUrl = this.chooseHanziList[this.currentIndex].audioSrc;
    },
    initGame() {
      this.rightHanziIndexArr = [];
      this.lockIndex = 0;
      this.choosedHanziArr = [];
      this.insertIndex = 0;
      this.chooseImgIndex = null;
      this.isShowAllWords = false;
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleChooseImg(id, index, isFromSocket = false) {
      if (!isFromSocket) {
        this.imgSocketInfo = {
          id,
          index,
          value: Math.random(),
        };
      }
      this.$emit("handleCheckImgEvent", id, this.currentIndex);
      this.chooseImgIndex = index;
      if (id === this.answerList[this.currentIndex]) {
        playCorrectSound(true, false);
        setTimeout(() => {
          this.isImgPage = false;
        }, 1000);
      } else {
        playCorrectSound(false);
      }
    },
    handleChooseHanzi(id, index, isFromSocket = false) {
      console.log(index);
      if (!isFromSocket) {
        this.hanziSocketInfo = {
          id,
          index,
          value: Math.random(),
        };
      }
      if (
        id ===
        this.chooseHanziList[this.currentIndex].hanziRightList[this.lockIndex]
          .id
      ) {
        this.choosedHanziArr.push(index);
        setTimeout(() => {
          this.lockIndex++;
          this.insertIndex++;

          this.rightHanziIndexArr.push(this.lockIndex);
          this.checkToNext();
          console.log(this.rightHanziIndexArr);
        }, 10);
      }
    },
    checkToNext() {
      if (
        this.lockIndex >=
        this.chooseHanziList[this.currentIndex].hanziRightList.length
      ) {
        this.isShowAllWords = true;
        this.nextPart();
      }
    },
    nextPart() {
      setTimeout(() => {
        if (this.currentIndex === this.answerList.length - 1) {
          startConfetti();
          playCorrectSound();
          this.isLastStep = true;
        } else {
          playCorrectSound(true, false);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.liten-then-choose-words-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    background: #f7f4e0;
    border-radius: 58px;

    .choose-img-area {
      height: 100%;
      display: flex;
      align-items: center;
      .img-area {
        flex: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        .img-content {
          width: 80%;
          height: 70%;
          display: flex;
          flex-wrap: wrap;
          .img-item {
            width: 48%;
            margin-right: 2%;
            margin-bottom: 2%;
            display: inline-block;
            box-sizing: border-box;
            border: 10px solid #fff;
            cursor: pointer;
          }
          .active {
            border-color: #ce4c3f;
          }
          .isCorrect {
            border-color: #214e96;
          }
        }
      }
      .trumpet-area-right {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        .trumpet-area {
          width: 100px;
          height: 100px;
          background-color: #cd4c3f;
          border-radius: 50%;
          img {
            width: 30%;
            &:hover {
              opacity: 0.8;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .choose-hanzi-area {
    width: 100%;
    height: 100%;
    .answer-area {
      height: 70%;
      display: flex;
      align-items: center;
      .answer-img {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        img {
          width: 70%;
          border: 10px solid #fff;
          box-sizing: border-box;
          transform: rotate(-10deg);
        }
      }
      .answer-hanzi-area {
        flex: 2;
        height: 40%;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        .hanzi-item {
          width: 17%;
          height: 60%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 20px;
          border: 5px dashed #cfc8b0;
          border-radius: 20px;
          color: #f7f4e0;
          background: transparent;
          color: #fff;
          display: flex;
          flex-direction: column;
          padding: 20px 20px;
          span {
            visibility: hidden;
            display: inline-block;
          }
        }
        .active {
          border: 5px dashed #ce4c3f;
        }
        .hasInserted {
          border: none;
          background: #224e96;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 20px 20px;
          span {
            visibility: visible;
          }
        }
      }
      .answer-right-hanzi-area {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        .right-hanzi-box {
          border-radius: 40px;
          width: 40%;
          background: #224e96;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 5% 15%;
          span {
            display: inline-block;
            color: #fff;
          }
        }
      }
    }
    .choose-hanzi-area {
      height: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      .hanzi-item {
        box-sizing: border-box;
        width: 11%;
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 20px;
        background-color: #cd4c3f;
        border-bottom: 8px solid #ac3d32;
        border-radius: 20px;
        color: #fff;
        justify-content: center;
        align-items: center;

        margin: 0 8px;
        background-color: #ce524c;
        border-bottom: 12px solid #a53d32;
        border-top: 12px solid #d87065;
        border-radius: 30px;
        cursor: pointer;
        span {
          display: inline-block;
        }
      }
      .dispear {
        opacity: 0;
      }
      .hanzi-item:hover {
        background-color: #912016;
        border-top: 8px solid #6e0c03;
        border-bottom: none;
      }
    }
  }
}
</style>
